import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const ParentApi = {
    /**
     * Gets parents
     *
     * @param {number} roomId
     * @param {number} lastLoadedId
     * @param {number} count
     * @return {Object} array of parents or an error
     */
    getParents: async (
        roomId = null,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/list/${roomId}/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return await result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets random parents
     *
     * @param {number} count
     * @return {Object} array of parents or an error
     */
    getRandomParents: async (count = constants.DEFAULTS.PAGE_SIZE) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/random/${count}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets user's parents
     *
     * @param {number} userId
     * @param {number} lastLoadedId
     * @param {number} count
     * @return {Object} array of parents or an error
     */
    getUserParents: async (
        userId,
        count = constants.DEFAULTS.PAGE_SIZE,
        lastLoadedId = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/user/${userId}/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "USER_NOT_SPECIFIED":
                    error = translations.Errors.UserNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets parents
     *
     * @param {number} parentId
     * @param {number} userId
     * @param {number} roomId
     * @return {Object} array of children of the parent or an error
     */
    getParentChildren: async (parentId, userId) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/children/${parentId}/${userId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "PARENT_NOT_FOUND":
                    error = translations.Parents.ParentNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Deletes the parent
     *
     * @param {number} id
     * @param {number} roomId
     */
    deleteParent: async (id, roomId = null) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/delete`;
            const params = { id: id };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                case "PARENT_HAS_CHILDREN":
                    error = translations.Alerts.CannotDeleteWithChildren;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds a parent
     *
     * @param {string} content
     * @param {number} userId
     * @param {number} roomId
     */
    addParent: async (content, userId, roomId = null) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/add`;
            const params = { content: content, userId: userId, roomId: roomId };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "PARENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Edits the parent
     *
     * @param {number} id
     * @param {string} content
     * @param {number} roomId
     */
    editParent: async (id, content, roomId = null) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/edit`;
            const params = { id: id, content: content };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "PARENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Gets parent by id
     *
     * @param {number} id
     * @param {number} userId
     * @param {number} roomId
     */
    getParent: async (id, userId = null, roomId = null) => {
        try {
            const endpointUrl = `${SERVICE_URL}/parents/parent/${id}/${userId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "PARENT_NOT_FOUND":
                    error = translations.Parents.ParentNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return {};
    },
};
