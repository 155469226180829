import React from "react";

const Alert = ({ children, onClickTrigger }) => {
    return (
        <div className="alert-container" onClick={onClickTrigger}>
            <div className="alert-close-btn">&times;</div>
            {children}
        </div>
    );
};

export default Alert;
