import React, { useState, useContext } from "react";
import { constants, translations } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { ChildApi } from "../../api/childApi.js";

const ParentChildForm = ({
    parent,
    childAdded,
    isAutoFocus,
    additionalCssClass = "",
}) => {
    const [inputs, setInputs] = useState({ content: "" });
    const [isFocused, setIsFocused] = useState(false);
    const [errorMessage, setError] = useState("");
    const { currentUser } = useContext(AuthContext);

    const { addChild } = ChildApi;

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const resetForm = () => {
        Object.keys(inputs).forEach((key) => {
            setInputs((prevInputs) => ({ ...prevInputs, [key]: "" }));
        });
    };

    const checkContent = () => {
        return inputs.content.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const handleFormSubmit = async (e) => {
        e.target.disabled = true;
        e.preventDefault();

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        }

        const result = await addChild(inputs.content, parent, currentUser.id);

        if (!result?.error) {
            resetForm();
            childAdded(true);
            setIsFocused(false);
        } else {
            setError(result.error);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    // const handleBlur = () => {
    //     setIsFocused(false);
    // };

    return (
        <div className={`add-child-form ${additionalCssClass}`}>
            <textarea
                name="content"
                value={inputs.content}
                onChange={handleChange}
                rows={isFocused ? 3 : 1} // Adjust the number of rows as needed
                onFocus={handleFocus}
                placeholder={translations.Children.WriteChild}
                autoFocus={isAutoFocus ? true : undefined}
                disabled={currentUser == null}
            ></textarea>
            {isFocused && currentUser != null && (
                <button
                    className="submit"
                    onClick={handleFormSubmit}
                    disabled={!checkContent()}
                >
                    {translations.Forms.Send}
                </button>
            )}

            {errorMessage && <div className="error">{errorMessage}</div>}

            {!currentUser && (
                <div className="alert-inline">
                    <p>
                        {translations.Alerts.CannotAddChild}{" "}
                        <a href="/login">{translations.Auth.Login}</a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default ParentChildForm;
