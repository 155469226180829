import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { translations, constants } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { ChildApi } from "../api/childApi.js";
import { UserApi } from "../api/userApi.js";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import Child from "../components/child/Child.jsx";
import ConfirmDeleteChild from "../components/child/ConfirmDeleteChild.jsx";
import ConfirmModal from "../components/common/ConfirmModal.jsx";

const UserChildrenPage = () => {
    const [records, setChildren] = useState([]);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [childToDelete, setChildToDelete] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [skip, setSkip] = useState(0);
    const [trigger, setTrigger] = useState(false);
    const { type } = useParams();
    const [dynamicType, setDynamicType] = useState(
        type ? parseInt(type, 10) : constants.LIST_TYPE.RATING
    );

    const { id } = useParams();

    const { getUserChildren, getUserChildrenByRating, deleteChild } = ChildApi;
    const { getUserInfo } = UserApi;

    useEffect(() => {
        setChildren([]);
    }, [dynamicType]);

    useEffect(() => {
        async function fetchUserData() {
            if (!id && !currentUser) {
                return;
            }

            const userId = id != null ? id : currentUser.id;
            const result = await getUserInfo(userId);

            if (!result?.error) {
                setUser(result);
            }
        }

        fetchUserData();
    }, [currentUser, getUserInfo, id]);

    useEffect(() => {
        async function fetchData() {
            if (!id && !currentUser) {
                setIsUnavailable(true);
                return;
            }

            setIsLoading(true);
            setIsUnavailable(false);

            const userId = id != null ? id : currentUser.id;

            const result =
                dynamicType === constants.LIST_TYPE.RATING
                    ? await getUserChildrenByRating(
                          userId,
                          constants.DEFAULTS.PAGE_SIZE,
                          skip,
                          currentUser?.id
                      )
                    : await getUserChildren(
                          userId,
                          constants.DEFAULTS.PAGE_SIZE,
                          lastLoadedId,
                          currentUser?.id
                      );

            setIsLoading(false);

            if (!result.error) {
                if (result.length > 0) {
                    setChildren((prevRecords) => [...prevRecords, ...result]);
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }
        }

        fetchData();
    }, [
        lastLoadedId,
        currentUser,
        id,
        getUserChildren,
        getUserChildrenByRating,
        trigger,
        dynamicType,
        skip,
    ]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            records?.length > 0 ? records[records.length - 1].id : 0
        );
        setSkip(records?.length > 0 ? records.length : 0);
    };

    // Sets child with specified id for removal
    const handleDeleteChild = (id) => {
        const selected = records.find((record) => record.id === id);
        setChildToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setChildToDelete(null);
    };

    // Marks selected child as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (childToDelete) {
            const result = await deleteChild(childToDelete.id);

            if (!result.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== childToDelete.id
                );
                setChildren(updatedRecords);
            }
        }

        setChildToDelete(null);
    };

    const handleTypeChange = (newType) => {
        if (newType === dynamicType) {
            setTrigger((prev) => !prev);
        }

        setDynamicType(newType);
        setSkip(0);
        setLastLoadedId(0);
    };

    return (
        <div className="children-content">
            <h1>
                {id != null && id == currentUser?.id && (
                    <span>
                        {translations.Children.UserChildren} {user?.nick}
                    </span>
                )}
                {(id == null || id == currentUser?.id) &&
                    translations.Menu.MyChildren}
            </h1>

            <div className="page-menu">
                <div className="menu-item">
                    <span
                        className={
                            dynamicType === constants.LIST_TYPE.RATING
                                ? "active"
                                : ""
                        }
                        onClick={() => {
                            if (dynamicType !== constants.LIST_TYPE.RATING)
                                handleTypeChange(constants.LIST_TYPE.RATING);
                        }}
                    >
                        {translations.Menu.ByRating}
                    </span>
                </div>
                <div className="menu-item">
                    <span
                        className={
                            dynamicType === constants.LIST_TYPE.ALL
                                ? "active"
                                : ""
                        }
                        onClick={() => {
                            if (dynamicType !== constants.LIST_TYPE.ALL)
                                handleTypeChange(constants.LIST_TYPE.ALL);
                        }}
                    >
                        {translations.Menu.Recent}
                    </span>
                </div>
            </div>

            {childToDelete && (
                <ConfirmModal
                    contentComponent={
                        <ConfirmDeleteChild child={childToDelete} />
                    }
                    closeModal={handleCloseModal}
                    confirmDelete={handleDeleteComfirmed}
                />
            )}

            {records.map((child) => (
                <div key={child.id}>
                    <Child
                        child={child}
                        deleteChild={handleDeleteChild}
                        showVoting={false}
                        showRating={id == null || id == currentUser?.id}
                    />
                </div>
            ))}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            {!isLoading && !isEndOfList && (
                <div className="filter-buttons">
                    <button onClick={handleLoadMore}>
                        {translations.Buttons.LoadMore}
                    </button>
                </div>
            )}

            {isLoading && <Spinner />}

            {!isLoading && isEndOfList && (
                <NoItemsCard isEmpty={records?.length === 0} />
            )}
        </div>
    );
};

export default UserChildrenPage;
