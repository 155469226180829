import React from "react";
import { Link } from "react-router-dom";
import { translations } from "../../base.js";
import Paper from "../../images/paper.svg";
import Pencil from "../../images/pencil.svg";

const ParentSidebar = ({
    parentId,
    showChildForm,
    showChildren,
    parentChildrenCnt,
    showAlert = () => {},
}) => {
    const handleShowChildrenWithAutofocus = () => {
        showChildren(parentId, true);
    };

    return (
        <div className="parent-sidebar">
            <div className="buttons">
                {showChildForm && (
                    <img
                        className="write"
                        src={Pencil}
                        alt={translations.Icons.Write}
                        title={translations.Icons.Write}
                        onClick={handleShowChildrenWithAutofocus}
                    />
                )}
                {!showChildForm && (
                    <img
                        className="write"
                        src={Pencil}
                        alt={translations.Icons.Write}
                        title={translations.Icons.Write}
                        onClick={showAlert}
                    />
                )}
                <Link to={`/parent/${parentId}`} target="_blank">
                    <div className="count-container">
                        <img
                            className="count-img"
                            src={Paper}
                            alt={translations.Icons.ParentChildren}
                            title={translations.Icons.ParentChildren}
                        />
                        <div className="count-text">{parentChildrenCnt}</div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ParentSidebar;
