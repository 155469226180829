import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { translations } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { getTimeAgo } from "../../utils/dates.js";
import ParentEditForm from "./ParentEditForm.jsx";
import ParentSidebar from "./ParentSidebar.jsx";
import Alert from "../controls/Alert.jsx";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

const Parent = ({
    parent,
    parentChildrenCnt = 0,
    showChildren = () => {},
    deleteParent = () => {},
    isShowDeleteButton = true,
    cardClass = "",
    isShowSidebar = true,
}) => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isShowDeleteAlert, setIsShowDeleteAlert] = useState(false);
    const [isShowAddAlert, setIsShowAddAlert] = useState(false);
    const { currentUser } = useContext(AuthContext);

    const isCanEdit = currentUser?.isSuperAdmin;

    // User is the author
    const isOwner = currentUser?.id === parent.userId;

    // Parent has children
    const isHasChildren = parentChildrenCnt > 0;

    // User is the author
    const isCanDelete =
        currentUser?.isSuperAdmin || (isOwner && !isHasChildren);

    const handleShowForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const handleEditFormSubmitted = (success, newContent = null) => {
        if (success) {
            setIsFormVisible((prev) => !prev);

            if (newContent) {
                parent.content = newContent;
            }
        }
    };

    const handleEditFormClosed = () => {
        setIsFormVisible(false);
    };

    const handleShowChildren = () => {
        showChildren(parent.id);
    };

    const handleDelete = async (e) => {
        deleteParent(parent.id);
    };

    const handleDeleteNotAllowed = async (e) => {
        setIsShowDeleteAlert(!isShowDeleteAlert);
    };

    const handleAddNotAllowed = async (e) => {
        setIsShowAddAlert(!isShowAddAlert);
    };

    return (
        <div className={`card ${cardClass}`}>
            <div className="flex-container">
                {isShowDeleteAlert && (
                    <Alert onClickTrigger={handleDeleteNotAllowed}>
                        <p>{translations.Alerts.CannotDeleteWithChildren}</p>
                        <p>
                            {translations.Alerts.IfSetupIsWrong}
                            <a href="/contacts" target="_blank">
                                {translations.Menu.Contacts}
                            </a>
                        </p>
                    </Alert>
                )}
                {isShowAddAlert && (
                    <Alert onClickTrigger={handleAddNotAllowed}>
                        <p>{translations.Alerts.CannotAddChild}</p>
                        <p>
                            <a href="/login">{translations.Auth.Login}</a>
                        </p>
                    </Alert>
                )}
                <div className="card-content">
                    {!isFormVisible && isShowSidebar && (
                        <div
                            className="content clickable parent"
                            onClick={handleShowChildren}
                        >
                            {parent.content.split("\n").map((item, key) => {
                                return <p key={key}>{item}</p>;
                            })}
                        </div>
                    )}
                    {!isFormVisible && !isShowSidebar && (
                        <div className="parent-content">
                            {parent.content.split("\n").map((item, key) => {
                                return <p key={key}>{item}</p>;
                            })}
                        </div>
                    )}
                    {currentUser && isFormVisible && (
                        <ParentEditForm
                            parent={parent}
                            formSubmitted={handleEditFormSubmitted}
                            formClosed={handleEditFormClosed}
                        />
                    )}
                    <div className="content-sub parent-sub">
                        <div className="info">
                            {getTimeAgo(parent.created)} &middot;{" "}
                            <Link to={`/user/${parent.userId}`}>
                                @{parent.nick}
                            </Link>{" "}
                            {parent.modified && (
                                <span>
                                    <br />[{translations.DateAndTime.Modified}{" "}
                                    {getTimeAgo(parent.modified)}]{" "}
                                </span>
                            )}
                        </div>
                        <div className="menu">
                            <div className="links">
                                {isCanEdit && (
                                    <div className="link-item">
                                        <img
                                            src={EditIcon}
                                            alt={translations.Icons.Edit}
                                            title={translations.Icons.Edit}
                                            onClick={handleShowForm}
                                        />
                                    </div>
                                )}

                                {isShowDeleteButton && isCanDelete && (
                                    <div className="link-item">
                                        <img
                                            src={DeleteIcon}
                                            alt={translations.Icons.Delete}
                                            title={translations.Icons.Delete}
                                            onClick={handleDelete}
                                        />
                                    </div>
                                )}

                                {isShowDeleteButton &&
                                    !isCanDelete &&
                                    isOwner &&
                                    isHasChildren && (
                                        <div className="link-item">
                                            <div>
                                                <img
                                                    src={DeleteIcon}
                                                    alt={
                                                        translations.Icons
                                                            .Delete
                                                    }
                                                    title={
                                                        translations.Icons
                                                            .Delete
                                                    }
                                                    onClick={
                                                        handleDeleteNotAllowed
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                {isShowSidebar && (
                    <ParentSidebar
                        parentId={parent.id}
                        showChildForm={currentUser != null}
                        parentChildrenCnt={parentChildrenCnt}
                        showChildren={showChildren}
                        showAlert={handleAddNotAllowed}
                    />
                )}
            </div>
        </div>
    );
};

export default Parent;
