import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { translations } from "../base.js";
import { AuthContext } from "../context/authContext.js";
import { ParentApi } from "../api/parentApi.js";
import { RoomApi } from "../api/roomApi.js";
import { ChildApi } from "../api/childApi.js";
import { checkUserAccess } from "../utils/rooms.js";
import RoomWidgets from "../components/common/RoomWidgets.jsx";
import Child from "../components/child/Child.jsx";
import Parent from "../components/parent/Parent.jsx";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";
import ParentChildForm from "../components/parent/ParentChildForm.jsx";
import ConfirmDeleteChild from "../components/child/ConfirmDeleteChild.jsx";
import ConfirmModal from "../components/common/ConfirmModal.jsx";

const ParentPage = () => {
    const { id } = useParams();
    const [room, setRoom] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const [isAccessAllowed, setIsAccessAllowed] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [errorMessage, setError] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [isRoomLoading, setIsRoomLoading] = useState(true);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [parent, setParent] = useState(null);
    const [children, setChildren] = useState(null);
    const [childToDelete, setChildToDelete] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const [trigger, setTrigger] = useState(false);

    const { getParent } = ParentApi;
    const { getRoomInfo } = RoomApi;
    const { deleteChild } = ChildApi;

    useEffect(() => {
        const fetchData = async () => {
            if (parent) {
                if (parent.roomId) {
                    setIsRoomLoading(true);

                    const result = await getRoomInfo(parent.roomId);

                    setIsRoomLoading(false);

                    if (result && !result.error) {
                        setRoom(result);
                        setShowSidebar(true);

                        const canAccess = checkUserAccess(
                            currentUser ? currentUser.id : null,
                            result
                        );

                        if (canAccess) {
                            setIsAccessAllowed(true);
                        } else {
                            setDisplayErrorMessage(true);
                        }
                    }
                } else {
                    setIsAccessAllowed(true);
                }
            }
        };

        fetchData();
    }, [getRoomInfo, checkUserAccess, currentUser, parent]);

    useEffect(() => {
        const fetchData = async () => {
            setChildren([]);

            setIsLoading(true);

            const result = await getParent(
                id,
                currentUser ? currentUser.id : null
            );

            if (!result?.error) {
                const { children, ...parent } = result;
                setParent(parent);
                setChildren(children);
                setIsEndOfList(children.length === 0);
            } else {
                setIsUnavailable(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [id, getParent, trigger, currentUser]);

    const handleParentChildFormSubmitted = (success) => {
        if (success) {
            setTrigger(!trigger);
        }
    };

    // Sets child with specified id for removal
    const handleDeleteChild = (id) => {
        const selected = children.find((record) => record.id === id);
        setChildToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setChildToDelete(null);
    };

    // Marks selected child as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (childToDelete) {
            const result = await deleteChild(childToDelete.id);

            if (!result?.error) {
                const updatedRecords = children.filter(
                    (record) => record.id !== childToDelete.id
                );

                setChildren(updatedRecords);
                setIsEndOfList(updatedRecords.length === 0);
            }
        }

        setChildToDelete(null);
    };

    return (
        <div className="room">
            <Helmet>
                <title>
                    {isAccessAllowed == true && parent != null
                        ? parent.content
                        : translations.Menu.Parent}
                </title>
            </Helmet>

            {isAccessAllowed == true && parent != null && (
                <div className="two-column-container">
                    <div className="parent-page">
                        <h1>{translations.Menu.Parent}</h1>

                        {childToDelete && (
                            <ConfirmModal
                                contentComponent={
                                    <ConfirmDeleteChild child={childToDelete} />
                                }
                                closeModal={handleCloseModal}
                                confirmDelete={handleDeleteComfirmed}
                            />
                        )}

                        <Parent
                            parent={parent}
                            parentChildrenCnt={children?.length}
                            cardClass="highlighted"
                            isShowDeleteButton={false}
                            isShowSidebar={false}
                        />

                        {parent && (
                            <ParentChildForm
                                parent={parent}
                                childAdded={handleParentChildFormSubmitted}
                            />
                        )}
                        <h2>{translations.Menu.Children}</h2>
                        <div className="children-content">
                            {children &&
                                children.length > 0 &&
                                children.map((item) => (
                                    <Child
                                        key={item.id}
                                        child={{
                                            ...item,
                                            parentContent: parent.content,
                                        }}
                                        deleteChild={handleDeleteChild}
                                        hasParentLink={false}
                                    />
                                ))}

                            {isLoading && <Spinner />}

                            {!isLoading && isEndOfList && (
                                <NoItemsCard isEmpty={true} />
                            )}
                        </div>

                        {errorMessage && (
                            <p className="error-message-block">
                                {errorMessage}
                            </p>
                        )}
                        {isUnavailable && (
                            <div className="error-message-block">
                                {
                                    translations.Errors
                                        .ServiceIsTemporaryUnavalable
                                }
                            </div>
                        )}
                    </div>

                    {showSidebar && room != null && (
                        <div className="right-sidebar">
                            <RoomWidgets roomId={room.id} />
                        </div>
                    )}
                </div>
            )}

            {!parent && isRoomLoading && <Spinner />}

            {displayErrorMessage && (
                <div className="error-message-block">
                    {translations.Errors.RoomAccessDenied}
                </div>
            )}
        </div>
    );
};

export default ParentPage;
