export const translations = {
    SiteName: "LBF",
    SiteTitle: "Let's Be Funny",
    SiteDescription:
        "LetsBeFunny - Making people laugh is rather more fun than laughing ourselves :)",
    Common: {
        User: "Author",
        Rating: "Funniness",
        ContactsContent:
            "If you have any questions or suggestions, please contact us at",
    },
    Menu: {
        Home: "Let's Be Funny",
        Parents: "Setups",
        Parent: "Setup",
        MyParents: "My setups",
        Children: "Punchlines",
        MyChildren: "My punchlines",
        Favorites: "Favorites",
        Comments: "Comments",
        Top: "Top",
        TopRecent: "Recent",
        TopAll: "For all time",
        Recent: "Recent",
        ByRating: "Best",
        Forum: "Forum",
        Statistics: "Statistics",
        Badges: "Badges",
        Contacts: "Contacts",
        Feedbacks: "Feedback",
        User: "Author",
        Users: "Authors",
        About: "About",
        ParentAndChildFrom: "Joke from",
        ParentAndChild: "Joke",
        ParentFrom: "Setup from",
        Notifications: "Notifications",
        Messages: "Messages",
        Search: "Search",
        News: "News",
        Rooms: "Rooms",
        RoomParents: "Room setups",
        RoomChildren: "Room punchlines",
        Chat: "Chat",
    },
    Auth: {
        Enter: "Log in",
        Login: "Log in",
        Email: "E-mail",
        Nickname: "Nickname",
        Password: "Passsword",
        CurrentPassword: "Current pussword",
        NewPassword: "New password",
        ForgotPassword: "Forgot password?",
        ResetPassword: "Reset password",
        ResetPasswordWarning:
            "After you submit this form, we will send a confirmation link to the provided email address. Your account will remain inactive until you click the confirmation link.",
        ResetPasswordConfirmed: "Registration confirmed, you can now log in.",
        ResetPasswordRequested:
            "Follow the link we sent to your email address to confirm the password change. Your account will remain inactive until you click the confirmation link.",
        ConfirmResetPassword: "Reset password confirmation",
        ChangePassword: "Change the password?",
        LoginToChangePassword:
            "Please log in to the site to change your password.",
        ChangePasswordConfirmed:
            "Your password has been changed. You can now log in to the site with your new password.",
        YouCanRegister: "Maybe create a new account?",
        Logout: "Log out",
        Register: "Register",
        UserNameRules:
            '"Nickname" must be between {1} and {2} characters long and contain only letters, numbers, and spaces.',
        PasswordRules: '"Password" must be between {1} and {2} characters.',
        RegisterConfirmed:
            "Your account has been activated. You can now log in to the site.",
        ConfirmRegistration: "Registration confirmation",
        UserCreated:
            "Your account has been created, please check your email box and follow the link to activate it.",
        LoginToChat: "Log in to chat.",
    },
    User: {
        Location: "Location",
        Info: "About me",
        IWrote: "I wrote",
        Registered: "Registered",
        SendMessage: "Send message",
        ContactUser: "Let's chat",
        FindUser: "New chat with...",
    },
    Forms: {
        Submit: "Let's go!",
        Send: "Send",
        Save: "Save",
        Close: "Close",
        Delete: "Delete",
        Cancel: "No-no-no!",
        Find: "Find",
        AreYouSureDelete: "Are you sure you want to delete",
        WriteSomething: "Write something...",
    },
    Buttons: {
        LoadMore: "Load more",
        OrderByDate: "By date",
        OrderByRating: "By rating",
        OrderByUser: "By user",
    },
    Errors: {
        GeneralError: "Oops... Something went wrong :(",
        ServiceIsTemporaryUnavalable: "Service is temporary unavalable...",
        ContentTooShort: "It's too short, love.",
        TitleTooShort: "The title is too short, honey.",
        NameTooShort: "The name is too short, honey.",
        DescriptionTooLong: "The description is too long, sweety.",
        CurrentPasswordIncorrect: "The current password is not like this!",
        PasswordIncorrect: "The password is not like this!",
        UserNotFound: "User with this email doesn't exist.",
        UserInfoNotFound: "User not found",
        LoginUserNotActive:
            "User is not active, please check your email box for activation link.",
        ConfirmLinkInvalid:
            "Activation link is invalid, please contact site administrator on graysudak@gmail.com.",
        RegisterUserExists: "User with this email or nick already exists.",
        NickExists: "This nickname has been taken.",
        InvalidData: "Invalid data.",
        NotLoggedIn: "You are not logged in.",
        RoomAccessDenied: "You don't have access to this room.",
    },
    Alerts: {
        CannotDeleteWithChildren:
            "You can't delete this setup, because it has punchlines already.",
        CannotAddChild:
            "You can't add a new punchline, because you are not logged.",
        CannotAddParent:
            "You can't add a new parent, because you are not logged.",
        IfSetupIsWrong:
            "If you have concerns about this setup, please contact administrator: ",
        VotingNotAllowed:
            "Voting only allowed to the regitered users. Please login to vote.",
        CannotAddComment:
            "You can't add a comment, because you are not logged.",
    },
    Messages: {
        NoItems: "Nothing here. Yet... :)",
        NoMoreItems: "That's it. For now... :)",
        NoMessages:
            "You have no messages. Check out the author's page to send them a message.",
        Saved: "Saved",
        Me: "Me",
    },
    Children: {
        NewChild: "New punchline",
        UserChildren: "Author's punchlines",
        AlreadyInFavorites: "The punchline has already been added to favorites",
        Funny: "Funny",
        VeryFunny: "Hilarious :D",
        Neutral: "Ok",
        ChildNotFound: "Punchline not found",
        Child: "punchline",
        Wrote: "Wrote",
        WriteChild: "Write a punchline...",
    },
    Parents: {
        NewParent: "New setup",
        UserParents: "Author's setups",
        ParentNotFound: "Setup not found",
        Recent: "Recent",
        Random: "Random",
        Parent: "setup",
    },
    DateAndTime: {
        Modified: "Changed",
        JustNow: "just now",
        MinuteAgo: "minute ago",
        MinutesAgo: "minutes ago",
        MinutesAgo_2_3_4: "minutes ago",
        HourAgo: "hour ago",
        HoursAgo: "hours ago",
        HoursAgo_2_3_4: "hours ago",
        DayAgo: "day ago",
        DaysAgo: "days ago",
        DaysAgo_2_3_4: "days ago",
        Yesterday: "yesterday",
    },
    Badges: {
        USER_OF_THE_WEEK: "Author of the week",
        USER_OF_THE_MONTH: "Author of the month",
        CHILD_OF_THE_WEEK: "Joke of the week",
        BATTLE_WINNER: "Winner of a battle",
        CONTEST_WINNER: "Winner of a contest",
        DOUGLAS_ADAMS_42: "42",
        VIP: "VIP",
        USER_OF_THE_YEAR: "Author of the year",
        CHILD_OF_THE_YEAR: "Joke of the year",
        UNKNOWN: "Unknown badge",
    },
    Widgets: {
        RandomChild: "Random joke",
        UserOfTheWeek: "Author of the week",
        UserOfTheMonth: "Author of the month",
        UserOfTheYear: "Author of the year",
        ChildOfTheWeek: "Joke of the week",
        OnlineUsers: "Who's online",
        NoOneOnline: "Nobody. Yet...",
        TopUsers: "Top authors of all time",
        DynamicRatings: "Top of the month",
        SeeAll: "All authors",
    },
    NotFound: {
        Heading: "404 - I'm lost...",
        Content: "You must have missed the door...",
    },
    News: {
        comments: "comments",
        NewNews: "New news",
        SingleNews: "News",
        NewsNotFound: "News not found",
        AllNews: "All news",
    },
    Rooms: {
        Room: "Room",
        Rooms: "Rooms",
        RoomNotFound: "Room not found",
        AllRooms: "All rooms",
        Participants: "Participants",
        Created: "Created",
        Public: "For all",
        Private: "Private",
        Publics: "For all",
        Privates: "Priavte",
        NewRoom: "New room",
        AddParticipants: "Add some participants...",
        RoomName: "Name of the room...",
        RoomDescription: "What are we doing here...",
        MakePrivate: "Make it private?",
        RoomLinks: "Room links",
    },
    Feedbacks: {
        Feedback: "Dear creators",
        SendFeedback: "Send feedback",
        ThanksForYourFeedback: "Tanks for your feedback!",
    },
    Icons: {
        Edit: "Change",
        Delete: "Delete",
        AddToFavorites: "Add to favorites",
        RemoveFromFavorites: "Reomve from favorites",
        ParentChildren: "All punchlines of the setup",
        Write: "Write punchline",
        Comments: "Comments",
        Share: "Share",
        Notifications: "Notifications",
        MedalGold: "Золотая медаль",
        MedalSilver: "Серебряная медаль",
        MedalBronze: "Бронзовая медаль",
    },
    Chats: {
        ChatWith: "Chat with ",
        SendMessage: "Send a message...",
        RecentChats: "Recent chats",
        BigChatWelcome:
            "This is a common chat for all registered users. Welcome and enjoy chatting :)",
    },
};
