import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { translations } from "../../base.js";
import { AuthContext } from "../../context/authContext";
import FeedbackForm from "../../components/feedback/FeedbackForm.jsx";

const ExtendedMenu = ({ className }) => {
    const location = useLocation();
    const { currentUser } = useContext(AuthContext);
    const [isFeedbackFormVisible, setIsFeedbackFormVisible] = useState(false);

    const handleShowFeedback = () => {
        setIsFeedbackFormVisible(true);
    };

    const handleCloseForm = () => {
        setIsFeedbackFormVisible(false);
    };

    return (
        <>
            <div className={className}>
                <div className="left">
                    <ul>
                        <li>
                            <Link
                                to="/"
                                className={
                                    location.pathname === "/" ? "active" : ""
                                }
                            >
                                {translations.Menu.Parents}
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/children"
                                className={
                                    location.pathname === "/children"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.Children}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/top"
                                className={
                                    location.pathname === "/top" ? "active" : ""
                                }
                            >
                                {translations.Menu.Top}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className={
                                    location.pathname === "/about"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.About}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/search"
                                className={
                                    location.pathname === "/search"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.Search}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        {/*currentUser && (
                            <li
                                className="highlighted"
                                onClick={handleShowFeedback}
                            >
                                {translations.Feedbacks.Feedback}
                            </li>
                        )*/}
                        <li>
                            <Link
                                to="/rooms"
                                className={
                                    location.pathname === "/rooms"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.Rooms}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/chat"
                                className={
                                    location.pathname === "/chat"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.Chat}
                            </Link>
                        </li>
                        {!currentUser && (
                            <li>
                                <Link
                                    to="/login"
                                    className={
                                        location.pathname === "/login"
                                            ? "active"
                                            : ""
                                    }
                                >
                                    {translations.Auth.Login}
                                </Link>
                            </li>
                        )}
                        {!currentUser && (
                            <li>
                                <Link
                                    to="/register"
                                    className={
                                        location.pathname === "/register"
                                            ? "active"
                                            : ""
                                    }
                                >
                                    {translations.Auth.Register}
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link
                                to="/news"
                                className={
                                    location.pathname === "/news"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.News}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/badges"
                                className={
                                    location.pathname === "/badges"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.Badges}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/users"
                                className={
                                    location.pathname === "/users"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.Users}
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contacts"
                                className={
                                    location.pathname === "/contacts"
                                        ? "active"
                                        : ""
                                }
                            >
                                {translations.Menu.Contacts}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ExtendedMenu;
