import React, { useContext, useState } from "react";
import { translations } from "../../base.js";
import { getTimeAgo } from "../../utils/dates.js";
import { AuthContext } from "../../context/authContext.js";
import NewsEditForm from "./NewsEditForm.jsx";
import NewsComments from "../news/NewsComments";
import CommentIcon from "../../images/comments.svg";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

const News = ({ news, deleteNews, showTitle = true, showComments = false }) => {
    const { currentUser } = useContext(AuthContext);
    const [isCommentsVisible, setIsCommentsVisible] = useState(showComments);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const isSuperAdmin = currentUser?.isSuperAdmin;

    const handleShowForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const handleEditFormSubmitted = (
        success,
        newTitle = null,
        newContent = null
    ) => {
        if (success) {
            setIsFormVisible((prev) => !prev);

            if (newContent) {
                news.content = newContent;
            }

            if (newTitle) {
                news.title = newTitle;
            }
        }
    };

    const handleEditFormClosed = () => {
        setIsFormVisible(false);
    };

    const handleDelete = async (e) => {
        deleteNews(news.id);
    };

    const handleShowComments = () => {
        setIsCommentsVisible((prev) => !prev);
    };

    return (
        <div className="card" key={news.id}>
            <div className="flex-container">
                <div className="card-content">
                    {showTitle && !isFormVisible && <h2>{news.title}</h2>}
                    {!isFormVisible && (
                        <div className="content">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: news.content,
                                }}
                            />
                        </div>
                    )}
                    {isFormVisible && (
                        <NewsEditForm
                            news={news}
                            formSubmitted={handleEditFormSubmitted}
                            formClosed={handleEditFormClosed}
                        />
                    )}
                    <div className="content-sub">
                        <div className="info">{getTimeAgo(news.created)}</div>
                        <div className="menu">
                            <div className="links">
                                <div
                                    className="link-item comments"
                                    onClick={handleShowComments}
                                >
                                    <img
                                        src={CommentIcon}
                                        alt={translations.Icons.Comments}
                                        title={translations.Icons.Comments}
                                    />
                                    {news.commentCnt !== 0 && (
                                        <span className="count">
                                            {news.commentCnt}
                                        </span>
                                    )}
                                </div>
                                {isSuperAdmin && (
                                    <>
                                        <div
                                            className="link-item"
                                            onClick={handleShowForm}
                                        >
                                            <img
                                                src={EditIcon}
                                                alt={translations.Icons.Edit}
                                                title={translations.Icons.Edit}
                                            />
                                        </div>

                                        <div
                                            className="link-item"
                                            onClick={handleDelete}
                                        >
                                            <img
                                                src={DeleteIcon}
                                                alt={translations.Icons.Delete}
                                                title={
                                                    translations.Icons.Delete
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isCommentsVisible && <NewsComments newsId={news.id} />}
        </div>
    );
};

export default News;
