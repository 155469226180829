import React, { useState, useContext, useRef, useEffect } from "react";
import { constants, translations } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { ParentApi } from "../../api/parentApi.js";

const ParentForm = ({ formSubmitted, showAlert, roomId = null }) => {
    const [inputs, setInputs] = useState({ content: "" });
    const [errorMessage, setError] = useState("");
    const { currentUser } = useContext(AuthContext);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const containerRef = useRef(null);

    const { addParent } = ParentApi;

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const checkContent = () => {
        return inputs.content.length >= constants.VALIDATION.MIN_CONTENT_SIZE;
    };

    const resetForm = () => {
        setError("");
        Object.keys(inputs).forEach((key) => {
            setInputs((prevInputs) => ({ ...prevInputs, [key]: "" }));
        });
    };

    const handleFormSubmit = async (e) => {
        e.target.disabled = true;
        e.preventDefault();

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        }

        const result = await addParent(inputs.content, currentUser.id, roomId);

        if (!result?.error) {
            setIsFormVisible(false);
            formSubmitted(true);
            resetForm();
        } else {
            setError(result.error);
        }
    };

    const handleClose = () => {
        resetForm();
        setIsFormVisible(false);
    };

    const showFormHandler = () => {
        if (currentUser) {
            setIsFormVisible(true);
        } else {
            showAlert();
        }
    };

    // Add event listener to register a click outside of the form
    useEffect(() => {
        // If clicked anywhere outside of the form - hide the form
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                handleClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClose]);

    return (
        <>
            {!isFormVisible && (
                <div className="new-parent">
                    <button onClick={showFormHandler}>
                        {translations.Parents.NewParent}
                    </button>
                </div>
            )}
            {isFormVisible && (
                <div className="overlay">
                    <div className="overlay-modal" ref={containerRef}>
                        <div className="overlay-modal-content">
                            <p className="header">
                                {translations.Parents.NewParent}
                            </p>
                            <div className="form">
                                <textarea
                                    name="content"
                                    onKeyUp={handleChange}
                                    autoFocus
                                ></textarea>
                                {errorMessage && (
                                    <div className="error">{errorMessage}</div>
                                )}
                            </div>
                            <div className="controls">
                                <button
                                    className="submit"
                                    onClick={handleFormSubmit}
                                    disabled={!checkContent()}
                                >
                                    {translations.Forms.Submit}
                                </button>
                                <button className="close" onClick={handleClose}>
                                    {translations.Forms.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ParentForm;
