import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { translations } from "../base.js";
import { NewsApi } from "../api/newsApi.js";
import News from "../components/news/News.jsx";
import Spinner from "../components/common/Spinner.jsx";

const NewsSinglePage = () => {
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [errorMessage, setError] = useState("");
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [news, setNews] = useState(null);

    const { getNewsById } = NewsApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await getNewsById(id);

            setIsLoading(false);

            if (!result?.error) {
                setNews(result);
            } else if (result?.error) {
                setError(result.error);
            } else {
                setIsUnavailable(true);
            }
        };

        fetchData();
    }, [id, getNewsById]);

    return (
        <div className="standard-page narrow">
            <Helmet>
                {news && (
                    <title>{`${translations.News.SingleNews}: ${news.title}`}</title>
                )}
            </Helmet>

            {!isLoading && news && (
                <>
                    <h1>
                        {translations.News.SingleNews}: {news.title}
                    </h1>
                    <News news={news} showTitle={false} showComments={true} />
                </>
            )}

            {errorMessage && (
                <p className="error-message-block">{errorMessage}</p>
            )}

            {isLoading && <Spinner />}

            {isUnavailable && (
                <div className="error-message-block">
                    {translations.Errors.ServiceIsTemporaryUnavalable}
                </div>
            )}

            <p>
                <Link to="/news">{translations.News.AllNews}</Link>
            </p>
        </div>
    );
};

export default NewsSinglePage;
